import React from 'react'
import PropTypes from 'prop-types'

import MessagePage from '../components/message-page'

const LinkUsedUp = ({ location, title, message }) => {
  return (
    <MessagePage
      location={location}
      title="Link used up"
      message="You've used this link too many times."
    />
  )
}

LinkUsedUp.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}

export default LinkUsedUp
